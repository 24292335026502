import axios, { AxiosRequestHeaders } from 'axios';
import {
  TicketPackage,
  Team,
  ClientTicketPackageEx,
  BaseData,
  QualifyStatus,
  ClientSummary,
  ClientEvent,
  CredsValidationStatus,
  LeagueId,
  LeagueStatus,
  EventStatus,
  SalesReports,
  ReportsPage,
  ClientTicketPackage,
  InventoryItem,
  TaxStatusDetails,
  Commission,
  ActionLog,
  SingleEventInventory,
  TaxStatus,
} from './types';
import * as _ from 'ramda';

import { sleep } from './util';
import { fmt } from './helper';
import exp from 'node:constants';

const apiUrl = process.env.REACT_APP_API_URL;
let ACCESS_TOKEN = '';
export const setAccessToken = (value: string) => {
  ACCESS_TOKEN = value;
};

const toUrlSearchParams = (obj: Record<string, unknown>) => {
  return new URLSearchParams(
    Object.fromEntries(
      Object.entries(obj)
        .filter(([_key, val]) => val !== null && val !== undefined)
        .map(([key, val]) => [key, '' + val]),
    ),
  );
};

export const get = (path: string) => {
  return axios.get(apiUrl + path, {
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    },
  });
};
export const put = (path: string, body?: unknown) => {
  return axios.put(apiUrl + path, body, {
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    },
  });
};

export const post = (
  path: string,
  body?: unknown,
  headers: AxiosRequestHeaders = {},
) => {
  return axios.post(apiUrl + path, body, {
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
      ...headers,
    },
  });
};

export const DELETE = (path: string) => {
  return axios.delete(apiUrl + path, {
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    },
  });
};

export const getJson = async (path: string) => {
  const res = await get(path);
  return res.data;
};

export const getTicketPackages = async () => {
  const res = await get('/ticket-packages');
  return res.data;
};

export const getPlayoffTicketPackages = async () => {
  const res = await get(`/ticket-packages/playoffs`);
  return res.data;
};

export const getSalesReports = async (
  inventory_id: number,
  comp_sections?: string,
  refresh?: boolean,
) => {
  let path = `/sales/${inventory_id}?`;
  if (refresh) path += 'refresh=true&';
  if (comp_sections) path += `comp_sections=${comp_sections}`;
  const res = await get(path);
  return res.data as SalesReports;
};

export const updatePackage = async (id: number, update: TicketPackage) => {
  await put(`/ticket-packages/${id}`, update);
};

export const updateNoteHeader = async ({
  row,
  new_value,
  ref_source,
  ref_usage,
  api_key_field,
}: {
  row: any;
  new_value: string;
  api_key_field: string;
  ref_source: string;
  ref_usage: string;
}) => {
  const ref_id = row[api_key_field];
  await put(`/notes/note-header/${ref_source}/${ref_usage}/${ref_id}`, {
    content: new_value,
  });
};

export const updateNoteDetail = async ({
  row,
  new_value,
  ref_source,
  ref_usage,
  api_key_field,
}: {
  row: any;
  new_value: string;
  api_key_field: string;
  ref_source: string;
  ref_usage: string;
}) => {
  const ref_id = row[api_key_field];
  await put(`/notes/${ref_source}/${ref_usage}/${ref_id}`, {
    note: new_value,
  });
};

export const getNoteDetail = async ({
  row,
  ref_source,
  ref_usage,
  api_key_field,
}: {
  row: any;
  api_key_field: string;
  ref_source: string;
  ref_usage: string;
}) => {
  const ref_id = row[api_key_field];
  const res = await get(`/notes/${ref_source}/${ref_usage}/${ref_id}`);
  return res.data;
};

export const getNoteAbstract = async ({
  row,
  ref_source,
  ref_usage,
  api_key_field,
}: {
  row: any;
  api_key_field: string;
  ref_source: string;
  ref_usage: string;
}) => {
  const ref_id = row[api_key_field];
  const res = await get(`/notes/abstract/${ref_source}/${ref_usage}/${ref_id}`);
  return res.data;
};

export const isAnyNotes = async (ref_id: number, ref_source: string) => {
  const res = await get(`/notes/has-notes/${ref_source}/general/${ref_id}`);
  return res.data;
};

export const updateInventoryNote = async (
  item: any,
  note: string,
  api_key_field: string,
) => {
  const inv_id = item[api_key_field];
  await put(`/inventory/${inv_id}/note`, { note });
};

export const updateCustomerNote = async (
  item: any,
  note: string,
  api_key_field: string,
) => {
  const customer_id = item[api_key_field];
  await put(`/customers/${customer_id}/note`, { note });
};

export const getTeams = async (league: string) => {
  const res = await get(`/teams?league=${league}`);
  return res.data as Team[];
};

export const getPackage = async (id: number) => {
  const res = await get(`/ticket-packages/${id}`);
  return res.data as ClientTicketPackageEx;
};

export const getCommissions = async () => {
  const res = await get(`/commissions`);
  return res.data as Commission[];
};

export const getPackageEvents = async (id: number) => {
  const res = await get(`/ticket-packages/${id}/events`);
  let events = res.data as ClientEvent[];
  if (!events.length) return [];

  const usualVenueId = _.pipe(
    _.groupBy(({ venue }: ClientEvent) => '' + venue?.id),
    _.toPairs,
    _.sortBy((entry: [string, unknown[]]) => entry?.[1]?.length),
    _.last,
    ([idString]) => parseInt(idString as string, 10),
  )(events);

  events = _.map(
    (ev) => ({
      ...ev,
      is_usual_venue: ev?.venue?.id === usualVenueId,
    }),
    events,
  );

  return events.filter((e) => !/season tickets/i.test(e.name));
};

export const setStatus = async (id: number, status: QualifyStatus) => {
  await put(`/ticket-packages/${id}/qualify-status/${status}`);
};

export const setIsInventoryShown = async (id: number, is_shown: boolean) => {
  await put(`/ticket-packages/${id}/is_shown/${is_shown ? 'true' : 'false'}`);
};

export const setCredentialsStatus = async (
  id: number,
  status: CredsValidationStatus,
) => {
  await put(`/ticket-packages/${id}/creds-status/${status}`);
};

export const revalidateCredentials = async ({ id }: { id: number }) => {
  await put(`/ticket-packages/${id}/creds-status/valid`);
};

export const setCommission = async (
  id: number,
  commission_tier: string,
  tiq_commission: string | null,
) => {
  await put(
    `/ticket-packages/${id}/commission-tier/${commission_tier}/commission/${tiq_commission}`,
  );
};

export const setCommissionReview = async (
  { id }: { id: number },
  is_reviewed: boolean,
) => {
  await put(`/ticket-packages/${id}/review-commission/${is_reviewed}`);
};

export const setPaymentPlan = async (
  { id }: { id: number },
  is_plan: boolean,
) => {
  await put(`/ticket-packages/${id}/payment-plan/${is_plan}`);
};

export const setPriceReview = async (
  { id }: { id: number },
  is_requested: boolean,
) => {
  await put(`/ticket-packages/${id}/price-review-requested/${is_requested}`);
};

export const setAwaitingTickets = async (
  { id }: { id: number },
  is_awaiting_tickets: boolean,
) => {
  await put(`/ticket-packages/${id}/awaiting-tickets/${is_awaiting_tickets}`);
};

export const setAmpPricing = async ({ id }: { id: number }) => {
  await post(`/ticket-packages/${id}/amp-pricing`);
};

export const createInventory = async (
  id: number,
  event_ids: number[],
  selected_events: ClientEvent[],
) => {
  await post(`/ticket-packages/${id}/create-inventory`, {
    event_ids,
    events: selected_events,
  });
};

export const deleteInventory = async (
  tp_id: number,
  invs: { id: number; event: ClientEvent }[],
) => {
  const res = await post(`/ticket-packages/${tp_id}/delete-inventory`, {
    inv_ids: invs.map((i) => i.id),
    inv_events: invs.map((i) => i.event),
  });

  let failed_request_err = '';
  res.data.fail.forEach((f: ClientEvent, idx: number) => {
    failed_request_err += `event_name = ${
      f.name
    }, event_local_time = ${fmt.datetime(f.date)}  \n`;
  });

  if (failed_request_err) {
    throw new Error(
      `Fail to delete following inventory: ${failed_request_err}`,
    );
  }
};

export const archive = async (id: number, reason?: string) => {
  await post(
    `/ticket-packages/${id}/archive`,
    reason
      ? {
          reason,
        }
      : undefined,
  );
};

export const unarchive = async (id: number) => {
  await post(`/ticket-packages/${id}/unarchive`);
};

export const getClientSummary = async (id: number) => {
  const res = await get(`/clients/${id}/summary`);
  return res.data as ClientSummary;
};

export const getBaseData = async (opts: { include_test_data: boolean }) => {
  const data = await getJson(
    `/base-data?include_test_data=${opts.include_test_data}`,
  );
  return data as BaseData;
};

export const createPackage = async (
  client_id: number,
  ticketPackage: TicketPackage & { commission?: string },
) => {
  const res = await post(
    `/ticket-packages/clients/${client_id}`,
    ticketPackage,
  );
  return res.data as ClientTicketPackageEx;
};

export const updatePlayoffResponseProcess = async (
  {
    id,
    playoff_response_id,
  }: {
    id: number;
    playoff_response_id: number;
  },
  is_processed: boolean,
) => {
  await put(
    `/ticket-packages/${id}/playoff-response/${playoff_response_id}/is_processed/${is_processed}`,
  );
};

export const updatePlayoffPackageRecord = async (
  item: any,
  value: any,
  round_field?: string,
) => {
  const ticket_package_id = item.id;
  const [playoff_round_name, record_flag] = (round_field ?? '').split('&');
  // @ts-ignore
  const playoff_round_id = item[playoff_round_name].playoff_round_id;
  await put(
    `/ticket-packages/${ticket_package_id}/playoff-round/${playoff_round_id}/record`,
    {
      flag: record_flag,
      value: value,
    },
  );
};

export const updatePlayoffPackageRecordPaymentStatus = async ({
  ticket_package_id,
  playoff_round_id,
  value,
}: {
  ticket_package_id: number;
  playoff_round_id: number;
  value: string;
}) => {
  await put(
    `/ticket-packages/${ticket_package_id}/playoff-round/${playoff_round_id}/record`,
    {
      flag: 'payment_status',
      value: value,
    },
  );
};

export const getLeagueStatus = async () => {
  const res = await get('/league-status');
  return res.data;
};

export const updateLeagueStatus = (
  league_id: LeagueId,
  payload: Omit<LeagueStatus, 'league' | 'seq'>,
) => {
  return put(`/league-status/${league_id}`, payload);
};

export const getPlayoffGames = async () => {
  const res = await get('/playoff-games');
  return res.data;
};

export const upsertPlayoffGame = async ({
  playoff_round_id,
  home_team_id,
  status,
}: {
  playoff_round_id: number;
  home_team_id: number;
  status: EventStatus;
}) => {
  await put(
    `/playoff-games/round/${playoff_round_id}/team/${home_team_id}/status/${status}`,
  );
};

export const deletePlayoffGame = async ({
  playoff_round_id,
  home_team_id,
}: {
  playoff_round_id: number;
  home_team_id: number;
}) => {
  await DELETE(`/playoff-games/round/${playoff_round_id}/team/${home_team_id}`);
};

export const updateInventoryBroadcastable = async (
  inventory_id: number,
  is_broadcastable: string,
) => {
  await put(`/inventory/${inventory_id}/broadcastable/${is_broadcastable}`);
};

export const updateInventoryMarketplaceBroadcasted = async (
  inventory_id: number,
  is_broadcasted: boolean,
) => {
  await put(
    `/inventory/${inventory_id}/marketplace_broadcasted/${is_broadcasted}`,
  );
};

export const getSaleAnalysis = async (
  inventory_id: number,
  comp_price: number,
) => {
  const res = await get(`/sales/${inventory_id}/analysis/${comp_price}`);
  return res.data;
};

export const sendSaleEmail = async (
  inventory_id: number,
  payload: {
    client_email: string;
    email_vars: object;
    comp_sale: { [p: string]: unknown };
    bcc: string;
    pricing_factors: { id: number; value: string }[];
    is_sale_guarantee: boolean;
    is_email_required?: boolean;
  },
) => {
  await put(`/sales/${inventory_id}/sales_email`, payload);
};

export const getComparableSection = async (inventory_id: number) => {
  const res = await get(`/sales/${inventory_id}/comp_sections`);
  return res.data;
};

export const upsertComparableSection = async (
  inventory_id: number,
  comp_sections: string,
) => {
  await put(`/sales/${inventory_id}/comp_sections/${comp_sections}`);
};

export const deleteComparableSection = async (id: number) => {
  await DELETE(`/sales/comp_sections/${id}`);
};

export const getHomeSchedule = async (
  inventory_id: number,
  payload: {
    forward_days?: number;
    backward_days?: number;
  },
) => {
  let query = payload.backward_days
    ? `backwardDays=${payload.backward_days}`
    : '';
  if (payload.forward_days) query += `&forwardDays=${payload.forward_days}`;
  const res = await get(`/sales/${inventory_id}/schedule?${query}`);
  return res.data;
};

export const getNoBarcodeReport = async (is_v2 = false) => {
  const res = await get(`/no-barcode${is_v2 ? '/v2' : ''}`);
  return res.data;
};

export const getNoBarcodeReportAccessToken = async () => {
  const res = await get(`/no-barcode/v2/access_token`);
  return res.data;
};

export const updateNoBarcodeReport = async () => {
  await put(`/no-barcode`);
};

export const upsertNoBarcodeReportNote = async (
  item: any,
  note: string,
  api_key_field: string,
  type: string,
) => {
  const ref_id = item[api_key_field];
  await put(`/no-barcode/note/${type}/${ref_id}`, { note });
};

export const upsertNoBarcodeReportNoteV2 = async (
  item: any,
  value: { id: number; note: string },
  api_key_field: string,
) => {
  const ref_id = value.id;
  const ref_type =
    api_key_field === 'inv_ids'
      ? 'inventory'
      : api_key_field === 'tp_ids'
      ? 'ticket_package'
      : 'client';
  await put(`/no-barcode/note/${ref_type}/${ref_id}`, { note: value.note });
};

export const getReportsPage = async (): Promise<ReportsPage> => {
  const res = await get('/pages/reports');
  return res.data;
};

export const saveReportsPage = async (source: string): Promise<ReportsPage> => {
  const res = await post('/pages/reports', { source });
  return res.data;
};

export const getDocumentationPage = async (): Promise<ReportsPage> => {
  const res = await get('/pages/documentations');
  return res.data;
};

export const saveDocumentationPage = async (
  source: string,
): Promise<ReportsPage> => {
  const res = await post('/pages/documentations', { source });
  return res.data;
};

export const getClients = async () => {
  const res = await get('/clients');
  return res.data;
};

export const getClient = async (id: number) => {
  const res = await get(`/clients/${id}`);
  return res.data;
};

export const getTicketPackagesPricing = async () => {
  const res = await get('/pricing/ticket-packages');
  return res.data;
};

export const getEventsPricing = async () => {
  const res = await get('/pricing/events');
  return res.data;
};

export const getTeamsPricing = async () => {
  const res = await get('/pricing/teams');
  return res.data;
};

export const getInventoryPricing = async (pagination?: {
  page: number;
  pageSize: number;
}) => {
  const query = pagination
    ? `?page=${pagination.page}&pageSize=${pagination.pageSize}`
    : '';
  const res = await get(`/pricing/inventory${query}`);
  return res.data;
};

export const getInventoryPriceHistory = async (id: number) => {
  const res = await get(`/pricing/inventory/${id}/history`);
  return res.data;
};

export const getInventoryAdjustmentHistory = async (id: number) => {
  const res = await get(`/pricing/inventory/${id}/adjustment`);
  return res.data;
};

export const getClientInventory = async (client_id: number) => {
  const res = await get(`/inventory?client_id=${client_id}`);
  return res.data.items as InventoryItem[];
};

export const getTaxStatus = async (id: number) => {
  const res = await get(`/clients/${id}/tax-info`);
  return res.data as TaxStatusDetails | null;
};

export const invalidateBankAccount = async (client_id: number) => {
  await post(`/clients/${client_id}/invalidate-bank-account`);
};

export const getSeasons = async () => {
  const res = await get('/seasons');
  return res.data;
};

export const updateClientInfo = async (
  client_id: number,
  payload: { phone: string; email: string },
) => {
  await put(`/clients/${client_id}`, payload);
};

export const getTicketPackageActionLogs = async (tp_id: number) => {
  const res = await get(`/ticket-packages/${tp_id}/action-logs`);
  return res.data as ActionLog[] | null;
};

export const createSingleEventInventory = async (
  client_id: number,
  SingleEventInventory: SingleEventInventory,
) => {
  const res = await post(
    `/single-events/clients/${client_id}`,
    SingleEventInventory,
  );
  return res.data;
};

export const getSkyboxEvents = async (
  keywords: string,
  from: Date | null,
  to: Date | null,
) => {
  const res = await get(
    `/single-events/skybox?keywords=${keywords}&from=${
      from?.toISOString() ?? ''
    }&to=${to?.toISOString() ?? ''}`,
  );

  return res.data as ClientEvent[];
};

export const deleteSingleEventInventory = async (inv_id: number) => {
  await post(`/single-events/inventory/${inv_id}/delete`);
};

export const updateTicketPackageMarketplaceVerification = async (
  tp_id: number,
  is_marketplace_verified: boolean,
) => {
  await put(
    `/ticket-packages/${tp_id}/marketplace-verified/${is_marketplace_verified}`,
  );
};

export const validateTeamCredentials = async (creds_id: number) => {
  await put(`/clients/validate-credentials/${creds_id}`);
};

export const getCommissionDetailsByTier = async (
  client_id: number,
  commission_tier: string,
  pkg_id?: number,
) => {
  const res = await get(
    `/clients/${client_id}/commission/${commission_tier}${
      pkg_id ? `?pkg_id=${pkg_id}` : ''
    }`,
  );
  return res.data as {
    commission: string;
    third_party_cost: string;
    tp_source?: {
      league: LeagueId;
      id: number;
      team_abbrev: string;
      section: string;
      row: string;
      first_seat: string;
      last_seat: string;
      season: number;
    };
  };
};

export const getAwaitingBroadcastTickets = async () => {
  const res = await get('/inventory/awaiting-broadcast');
  return res.data;
};

export const broadcastInventory = async (inv_id: number) => {
  await put(`/inventory/broadcast/${inv_id}`);
};

export const unbroadcastInventory = async (inv_id: number) => {
  await put(`/inventory/unbroadcast/${inv_id}`);
};

export const getPayments = async () => {
  const res = await get('/payments');
  return res.data;
};

export const getBulkPayments = async () => {
  const res = await get('/payments/batches');
  return res.data;
};

export const getOverduePayments = async () => {
  const res = await get('/payments/overdue');
  return res.data;
};

export const initiateOverduePayments = async (inv_ids: number[]) => {
  await post('/payments/initiate-overdue', { inv_ids });
};

export const updateTaxStatus = async (client_id: number, status: TaxStatus) => {
  await put(`/clients/${client_id}/tax-status/${status}`);
};

export const uploadLegacyPayments = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  await post('/payments/upload-csv', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const getLastLegacyPaymentsImportDetails = async () => {
  const res = await get('/payments/last-import');
  return res.data;
};

export const test = async () => {};
