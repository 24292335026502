import h from '../../lib/react-hyperscript';
import {
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import {
  uploadLegacyPayments,
  getLastLegacyPaymentsImportDetails,
} from '../api';
import { useContext, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { AppContext } from '../store/context';
import { format } from './QueryDataGrid';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const PaymentFileUpload = () => {
  const { setNotification } = useContext(AppContext);
  const [file, setFile] = useState<File | null>();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [last_import, setLastImport] = useState<{
    [key: string]: string | number;
  } | null>(null);

  useEffect(() => {
    if (isDialogOpen) {
      getLastLegacyPaymentsImportDetails().then((data) => {
        console.log(data);
        setLastImport(data);
      });
    }
  }, [isDialogOpen]);

  const handleImport = async () => {
    setIsUploading(true);
    if (file) {
      uploadLegacyPayments(file)
        .then(() => {
          setIsUploading(false);
          setNotification({
            open: true,
            severity: 'success' as AlertColor,
            msg: 'Bill.com payments imported successfully',
          });
          setDialogOpen(false);
        })
        .catch((err) => {
          setIsUploading(false);
          setNotification({
            open: true,
            severity: 'error' as AlertColor,
            msg: `Failed to upload: ${err.message}`,
          });
        });
    }
  };

  return h(Stack, {}, [
    h(
      Button,
      {
        variant: 'contained',
        startIcon: h(CloudUploadIcon, {}),
        onClick: () => {
          setFile(null);
          setDialogOpen(true);
        },
      },
      'Import Bill.com payments',
    ),
    h(
      Dialog,
      {
        open: isDialogOpen,
        onClose: () => {
          setDialogOpen(false);
        },
        fullWidth: true,
        maxWidth: 'sm',
        scroll: 'paper',
        sx: { top: '64px' },
      },
      [
        h(
          DialogTitle,
          { sx: { fontWeight: 'bold', textAlign: 'center' } },
          'Import Bill.com payments',
        ),

        h(DialogContent, { sx: { paddingLeft: 5, paddingRight: 5 } }, [
          last_import
            ? h(Stack, { marginBottom: 2 }, [
                h(
                  Typography,
                  { fontSize: 15, lineHeight: 1 },
                  `Last import: ${last_import.detail} by ${
                    last_import.user_fullname
                  } at ${format.datetime({
                    value: String(last_import.created),
                  })}`,
                ),
              ])
            : null,

          h(VisuallyHiddenInput, {
            id: 'csv-file',
            type: 'file',
            name: 'file',
            accept: '.csv',
            multiple: false,
            onChange: (event) => {
              setFile(event.target.files?.[0] || null);
            },
          }),
          h(
            Box,
            {
              id: 'dropzone',
              padding: 5,
              border: '2px dashed #ccc',
              borderRadius: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              sx: {
                backgroundColor: isDragging ? '#f0f0f0' : 'transparent',
                borderColor: isDragging ? '#000' : '',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                ':hover': {
                  backgroundColor: '#f0f0f0',
                  borderColor: '#000',
                  transition: 'all 0.3s ease',
                },
              },
              onClick: () => {
                const fileInput = document.getElementById('csv-file');
                fileInput?.click();
              },
              onDragOver: (event) => {
                event.preventDefault();
                setIsDragging(true);
              },
              onDragLeave: (event) => {
                setIsDragging(false);
              },
              onDrop: (event) => {
                event.preventDefault();
                setIsDragging(false);
                const files = event.dataTransfer.files;
                if (files.length) setFile(files[0]);
              },
            },
            file
              ? [
                  h(FilePresentIcon, {
                    sx: { fontSize: 36 },
                    color: 'success',
                  }),
                  file.name,
                ]
              : [
                  h(CloudUploadIcon, {
                    sx: { fontSize: 36 },
                    color: 'primary',
                  }),
                  h(Stack, {}, [
                    h(
                      Typography,
                      {
                        sx: {
                          fontSize: 16,
                          lineHeight: 1.2,
                          fontWeight: 'bold',
                        },
                      },
                      'Drag and drop or upload file',
                    ),
                    h(
                      Typography,
                      {
                        sx: {
                          fontSize: 12,
                          lineHeight: 1,
                          color: 'text.secondary',
                        },
                      },
                      'File supported: .csv',
                    ),
                  ]),
                ],
          ),

          h(
            Stack,
            {
              direction: 'row',
              pt: 2,
              gap: 3,
              justifyContent: 'center',
            },
            [
              h(
                LoadingButton,
                {
                  key: 'save',
                  variant: 'contained',
                  color: 'primary',
                  size: 'small',
                  loading: isUploading,
                  disabled: !file,
                  onClick: handleImport,
                },
                'Import',
              ),

              h(
                Button,
                {
                  key: 'cancel',
                  variant: 'outlined',
                  color: 'primary',
                  size: 'small',
                  onClick: () => setDialogOpen(false),
                },
                'Cancel',
              ),
            ],
          ),
        ]),
      ],
    ),
  ]);
};
